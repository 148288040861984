import {Fragment} from "react";

export default function Logo(props) {
  return (
    <div className="header">
      <Fragment>{props.title}</Fragment>
      <img src={props.src} className="logo" alt={props.title}/>
    </div>
  )
}
