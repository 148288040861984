import isJsonString from "../isJsonString";
import PrediagnosisWorkflow from "../Workflows/PrediagnosisWorkflow";
import ReservationWorkflow from "../Workflows/ReservationWorkflow";
import storeAnswer from "../Store/storeAnswer";

export default function processAnswer(dispatcher, answer) {
  if(isJsonString(answer)) {
    const json = JSON.parse(answer)
    if (json.type.trim() === "prediagnosis") {
      dispatcher({type: 'setWorkflow', payload: 'prediagnosis'})
      dispatcher({type: 'setWorkflowNextStep', payload: 1})
      const workflow = {name: 'prediagnosis', nextStep: 1, parameters: {}}
      answer = PrediagnosisWorkflow(dispatcher, workflow)
    }
    if(json.type.trim() === "reservation") {
      dispatcher({type: 'setWorkflow', payload: 'reservation'})
      dispatcher({type: 'setWorkflowNextStep', payload: 1})
      const workflow = {name: 'reservation', nextStep: 1, parameters: {}}
      answer = ReservationWorkflow(dispatcher, workflow)
    }
  }

  storeAnswer(dispatcher, answer)
}
