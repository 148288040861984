import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import storeQuestion from "../functions/Store/storeQuestion";
import { useStateContext } from "../contexts/StateContext";
import {
  isPrediagnosisWorkflow,
  runPrediagnosisWorkflowHandler
} from "../functions/WorkflowHandlers/runPrediagnosisWorkflowHandler";
import { isNoWorkflow, runNoWorkflowHandler } from "../functions/WorkflowHandlers/runNoWorkflowHandler";
import {
  isReservationWorkflow,
  runReservationWorkflowHandler
} from "../functions/WorkflowHandlers/runRegistrationWorkflowHandler";

export default function MessageForm() {
  const messages = useSelector(state => state.messages.messages);
  const workflow = useSelector(state => state.workflow.workflow);
  const dispatch = useDispatch();
  const messageFormContext = useStateContext();
  const QuestionInputField = useRef(null);

  const handleChange = (event) => {
    messageFormContext.setAppState({...messageFormContext.appState, message: event.target.value});
    adjustHeight(event.target);
  };

  const handleKey = (event) => {
    if(event.key === 'Enter' && event.shiftKey === false) {
      handleSubmit(event);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!messageFormContext.appState.message.trim()) {
      return;
    }
    storeQuestion(dispatch, messageFormContext.appState.message);
    messageFormContext.setAppState({...messageFormContext.appState, 'message': ''});
    QuestionInputField.current.style.height = "auto"; // Reset height after submit

    if (isPrediagnosisWorkflow(workflow)) {
      runPrediagnosisWorkflowHandler(workflow, dispatch, messageFormContext);
    }

    if (isReservationWorkflow(workflow)) {
      runReservationWorkflowHandler(workflow, dispatch, messageFormContext);
    }

    if (isNoWorkflow(workflow)) {
      runNoWorkflowHandler(dispatch, messageFormContext, messages);
    }
  };

  useEffect(() => {
    QuestionInputField.current.focus();
    adjustHeight(QuestionInputField.current);
  }, []);

  const adjustHeight = (element) => {
    element.style.height = "auto"; // Reset the height
    element.style.height = (element.scrollHeight) + "px"; // Set the height to the scroll height
  };

  return (
      <div className="form">
        <form onSubmit={handleSubmit}>
        <textarea
            placeholder="Escribe un mensaje..."
            className="input"
            id="messageInput"
            value={messageFormContext.appState.message}
            onChange={handleChange}
            onKeyDown={handleKey}
            ref={QuestionInputField}
            rows="1"
        />
          <input type="submit" value="Enviar" className="submit"/>
        </form>
      </div>
  );
}
