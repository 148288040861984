import {nanoid} from "@reduxjs/toolkit";

const makeButton = (response, buttons, inputDisabled = true) => {
  return JSON.stringify({"type": "buttons", "response": response, "buttons": buttons, "inputDisabled": inputDisabled})
}

const initialState = {
  messages: [
    {
      key: nanoid(),
      message: makeButton('Hola, soy Gravi, ¿en qué te puedo ayudar?',
        [
          "Quiero realizar un pre-diagnóstico",
          "Quiero reservar una cita",
        ],
          false
      ),
      type: 'answer'
    },
  ],
  filters: {
    status: 'All',
  }
}
const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'addAnswer':
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            key: nanoid(),
            message: action.payload,
            type: 'answer',
          }
        ]
      }
    case 'addQuestion':
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            key: nanoid(),
            message: action.payload,
            type: 'question',
          }
        ]
      }
    default:
      return state
  }
}

export default messagesReducer
