import {combineReducers, configureStore} from "@reduxjs/toolkit"
import MessagesReducer from "./messageReducer";
import DisclaimerReducer from "./disclaimerReducer";
import WorkflowReducer from "./workflowReducer";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  messages: MessagesReducer,
  disclaimer: DisclaimerReducer,
  workflow: WorkflowReducer,
})

export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}
