import axios from "axios";

export default function processReservation(email, dateTime, name, surname, phone) {
  // Aquí va el email para el cliente
  axios.post('https://api.gravidachat.com/index.php', 'email=' + email + '&subject=Cita reservada&html_body=<p>Hola!</p><p>Hemos recibido tu solicitud y hemos reservado cita con los siguientes datos:</p><p>Fecha y hora: ' + dateTime + '<br>Nombre: ' + name + '<br>Apellidos: ' + surname + '<br>Teléfono: ' + phone + '</p>')
  // Aquí va el email para la clinica de fertilidad
  axios.post('https://api.gravidachat.com/index.php', 'email=danielraya@seo.barcelona&subject=Solicitud de cita desde Gravi&html_body=<p>Hola!</p><p>Se ha recibido una solicitud de cita con los siguientes datos:</p><p>Fecha y hora: ' + dateTime + '<br>Nombre: ' + name + '<br>Apellidos: ' + surname + '<br>Teléfono: ' + phone + '<br>Email: ' + email + '</p>')
  axios.post('https://apitest.portalns.es:9001/api_gravida/VRepro_Datos_dat/v1/citas?api_key=dpbaGlox4pV1GoC2nMBwhj7F6Hn55aKy', {
    fecha: dateTime.split(' ')[0],
    hora: dateTime.split(' ')[1],
    paciente: surname + ', ' + name,
    tlf: phone,
    email: email
  })
}
