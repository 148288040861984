import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
import {Provider} from "react-redux";
import {setupStore} from "./reducers/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={setupStore()}>
    <App/>
  </Provider>
);
