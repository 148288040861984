import dots from "../assets/images/dots.gif";
import {createRef, useEffect} from "react";
import {useSelector} from "react-redux";
import TextMessage from "./TextMessage";
import ButtonsMessage from "./ButtonsMessage";
import isJsonString from "../functions/isJsonString";
import MessageCheckbox from "./MessageCheckbox";

export default function Visualizer() {
  const visualizer = createRef()
  const container = createRef()
  const messages = useSelector((state) => state.messages.messages)
  const workflow = useSelector((state) => state.workflow.workflow)

  useEffect(() => {
    visualizer.current.scrollTop = container.current.offsetHeight
  })

  return (
    <div className="visualizer" ref={visualizer}>
      <div ref={container}>
        {
          messages.map((message) => {
            let type = null
            let json = null

            if (isJsonString(message.message)) {
              json = JSON.parse(message.message)
              type = json.type
            }

            switch (type) {
              case 'buttons': {
                return (
                  <ButtonsMessage message={message} key={message.key} workflow={workflow}/>
                )
              }
              case 'privacyPolicy': {
                return (
                  <TextMessage message={message} key={message.key} inputDisabled={true}>
                    <MessageCheckbox response={json.response} name={json.name} phone={json.phone} email={json.email}/>
                  </TextMessage>
                )
              }
              default: {
                return (
                  <TextMessage message={message} key={message.key}>
                    {message.message}
                  </TextMessage>
                )
              }
            }
          })
        }
        <img src={dots} className="dots" alt="Gravi Dots" id="dots"/>
      </div>
    </div>
  )
}
