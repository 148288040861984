import logo from './assets/images/logorojo-2.png';
import Visualizer from './components/Visualizer';
import './assets/css/App.css';
import MessageForm from "./components/MessageForm";
import Logo from "./components/Logo";
import Container from "./components/Container";
import Gravi from "./components/Gravi";
import Disclaimer from "./components/Disclaimer";
import React, {useState} from "react";
import {StateContext} from "./contexts/StateContext";

function App() {
  const [appState, setAppState] = useState({message: '', disabled: false})

  return (
    <StateContext.Provider value={{appState: appState, setAppState: setAppState}}>
      <Gravi>
        <Disclaimer/>
        <Logo src={logo} title="Gravi"/>
        <Container>
          <Visualizer/>
          <MessageForm/>
        </Container>
      </Gravi>
    </StateContext.Provider>
  )
}

export default App;
