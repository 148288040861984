import {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";

export default function Disclaimer() {
  const dispatch = useDispatch()
  const showDisclaimer = useSelector(state => state.disclaimer.disclaimer)
  const handleClick = () => {
    dispatch({'type': 'hideDisclaimer'})
  }
  const styles = {
    disclaimerContainer: {
      position: 'absolute',
      height: '100vh',
      width: '100vw',
      backgroundColor: '#000',
      opacity: 0.8,
      zIndex: 998,
    },
    disclaimer: {
      position: 'absolute',
      margin: '4vh 4vw',
      height: '92vh',
      width: '92vw',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      padding: '4vh 4vw',
      zIndex: 999,
      overflowY: 'scroll',
    },
    hidden: {
      display: 'none',
    }
  }

  return (
    <Fragment>
      <div style={showDisclaimer ? styles.disclaimerContainer : styles.hidden}></div>
      <div style={showDisclaimer ? styles.disclaimer : styles.hidden}>
        <div>
          <button onClick={() => handleClick()}>Cerrar</button>
        </div>
        <h2>POLÍTICA DE PRIVACIDAD</h2>
        <p>A través del presente documento se regula el tratamiento de los datos personales recabados por Centro
          Internacional de Reproducción Humana Asistida de Barcelona, S.L. (en adelante, CIRHAB).</p>

        <p>Esta política de protección de datos personales (‘Política de Privacidad’) tiene por objeto dar a conocer la
          manera en que obtenemos, tratamos y protegemos los datos personales que tratamos a raíz de nuestra
          actividad.</p>

        <h3>¿Quién es el responsable del tratamiento de sus datos personales?</h3>
        <p>El responsable del tratamiento de sus datos personales es CIRHAB con NIF B65006751 . Los datos del contacto
          son los siguientes:</p>

        <p>Av. Diagonal, 660, planta 16, 08034 Barcelona<br/>
          Teléfono: 93 206 64 89<br/>
          Correo electrónico: gravida@gravidabcn.com</p>

        <h3>¿Cómo se puede poner en contacto con el Delegado de Protección de Datos de CIRHAB?</h3>
        <p>Se le informa que CIRHAB ha nombrado un Delegado de Protección de Datos (“DPD”) ante el cual podrá poner de
          manifiesto cualquier cuestión relativa al tratamiento de sus datos personales. Podrá contactar con el Delegado
          de Protección de Datos a través de la siguiente dirección de correo electrónico: dpd@gravidabcn.com.</p>

        <h3>¿Con qué finalidades y legitimización trataremos sus datos personales?</h3>
        <p>Con carácter general, CIRHAB tratará sus datos personales para las finalidades y con la legitimación que se
          detalla a continuación:</p>

        <p><b>Tratamientos basados en su consentimiento:</b></p>
        <p>Podremos recabar y tratar sus datos personales cuando haya prestado específicamente su consentimiento; por
          ejemplo, para gestionar su registro en la página web y dar respuesta a las solicitudes realizadas a través de
          la misma, para la participación en concursos, para la gestión de curriculums vitae, para el envío de
          comunicaciones comerciales y realización de perfilado cuando haya marcado la correspondiente casilla al
          completar el formulario o cuando hayamos recabado su consentimiento en ferias, eventos y/o otras actividades
          que pudieran llevarse a cabo, así como para gestionar el alta como facultativo o colaborador sanitario en la
          Entidad.</p>

        <p><b>Tratamientos basados en la ejecución de un contrato:</b></p>
        <p>Podremos tratar sus datos personales en las relaciones precontractuales a solicitud de los interesados; por
          ejemplo, al gestionar solicitud de información con relación a los tratamientos que ofrecemos. De la misma
          manera, podremos tratar sus datos personales basados en la ejecución de un contrato; por ejemplo, cuando firma
          el consentimiento del tratamiento , entre otras finalidades.</p>

        <p><b>Tratamientos basados en el interés legítimo:</b></p>
        <p>Podremos tratar sus datos personales cuando dispongamos de un interés legal legítimo; por ejemplo, en
          determinados casos para el envío de comunicaciones comerciales y realización de perfilado a pacientes de
          CIRHAB o el envío de encuestas de calidad sobre los productos o servicios prestados, acciones de fidelización,
          el envío de consejos y recomendaciones de salud o para el desarrollo, cumplimiento, difusión y control de la
          Junta General de Accionistas, siempre que nuestro interés legítimo prevalezca sobre los derechos y libertades
          del interesado.</p>

        <h3>¿Cómo protegemos sus datos personales?</h3>
        <p>En CIRHAB tratamos sus datos personales con absoluta confidencialidad, comprometiéndonos a guardar secreto
          respecto de los mismos y a adoptar todas las medidas necesarias que eviten su alteración, pérdida, tratamiento
          o acceso no autorizado, todo esto de acuerdo con las obligaciones legales que nos apliquen como responsable
          del tratamiento de datos.</p>

        <p>En CIRHAB nos comprometemos a mantener y a implementar los niveles de seguridad necesarios, teniendo en
          cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos asociados.</p>

        <h3>¿Durante cuánto tiempo conservaremos sus datos personales?</h3>
        <p>Trataremos sus datos personales mientras permanezcan vigentes los consentimientos que nos haya otorgado o
          mientras no haya cancelado las relaciones contractuales o de negocio que mantiene con nosotros.</p>

        <p>Así, dejaremos de tratar sus datos personales una vez haya revocado su consentimiento o haya finalizado su
          relación contractual o de negocio con CIRHAB, siempre que sus datos no sean necesarios para las finalidades
          para las que fueron recogidos o tratados.</p>

        <p>Sin perjuicio de lo anterior, sus datos personales se mantendrán bloqueados durante los plazos de
          prescripción establecidos legalmente. En este sentido, el ejercicio del derecho de supresión no minorará los
          plazos de conservación legalmente establecidos.</p>

        <h3>¿A qué destinatarios podemos comunicar sus datos personales?</h3>
        <p>El destinatario de la información es CIRHAB y el resto de entidades del Grupo para las entidades que forman
          parte del Grup Assistència y sus compañías vinculadas (SCIAS, S.C.C.L.; Autogestió Sanitària, S.C.C.L.;
          BIOPAT, S.L.; AIALE, S.A.; AERETIC, S.L.; CIRHAB, S.L.; ANDAIRA, S.L.) por tratar los datos con idénticas
          finalidades a las indicadas anteriormente y de conformidad con el objeto social de cada una, mientras formen
          parte o se mantengan en el citado Grupo Societario, incluyendo las compañías vinculadas.</p>
        <h3>¿Cuáles son sus derechos en relación con los datos que nos facilite y cómo puede ejercitarlos?</h3>
        <p>De acuerdo con la normativa europea de protección de datos, dispone de los siguientes derechos:</p>

        <p><b>Derecho de acceso</b></p>
        <p>Usted tiene derecho, por una parte, a acceder a los datos personales relativos al mismo que trata CIRHAB y,
          por otra parte, a determinada información sobre cómo se tratan sus datos personales.</p>

        <p><b>Derecho de revocación del consentimiento</b></p>
        <p>Usted tiene derecho a retirar el consentimiento en cualquier momento cuando haya dado autorización para
          tratar sus datos personales. La retirada del consentimiento no afecta a la licitud del tratamiento previo a la
          retirada.</p>

        <p><b>Derecho de rectificación</b></p>
        <p>Usted tiene derecho a rectificar sus datos personales si estos son inexactos y a que se completen los datos
          personales que sean incompletos.</p>

        <p><b>Derecho de oposición</b></p>
        <p>Usted tiene derecho a oponerse al tratamiento de sus datos personales cuando dicho tratamiento tenga por
          objeto la mercadotecnia directa, esté basado en el interés legítimo o en una misión de interés público.</p>

        <p>Ejercitado este derecho, CIRHAB dejará de tratar sus datos salvo que acredite motivos imperiosos que
          prevalezcan sobre los intereses, derechos y libertades del interesado, o para la formulación, el ejercicio o
          la defensa de reclamaciones.</p>

        <p><b>Derecho de supresión</b></p>
        <p>Usted tiene derecho a la eliminación de sus datos personales cuando concurran determinadas circunstancias
          (entre otras, los datos ya no sean necesarios para la finalidad para la cual se recogieron, el consentimiento
          en el que se basaba el tratamiento sea revocado o el interesado se oponga al tratamiento).</p>

        <p><b>Derecho de limitación</b></p>
        <p>Usted tiene derecho a que en determinados casos sus datos dejen de ser tratados. Por ejemplo, puede ejercer
          este derecho cuando se haya opuesto al envío de comunicaciones comerciales, mientras atendemos a su
          solicitud.</p>

        <p><b>Derecho de portabilidad</b></p>
        <p>Usted tiene derecho a recibir los datos personales que ha facilitado a CIRHAB en un formato estructurado, de
          uso común y de lectura mecánica, y a transmitirlos a otro responsable, si se cumplen los requisitos
          siguientes:</p>

        <p>El tratamiento se basa en el consentimiento o en un contrato.<br/>
          El tratamiento se hace por medios automatizados.<br/>
          Derecho a no ser objeto de decisiones automatizadas<br/>
          Usted tiene derecho a no ser objeto de una decisión basada solamente en el tratamiento automatizado de sus
          datos, incluida la elaboración de perfiles, que produzca efectos jurídicos o que le afecte negativamente,
          salvo que la decisión sea necesaria para formalizar o ejecutar un contrato entre el interesado y CIRHAB, se
          base en el consentimiento explícito del interesado o esté autorizada por el derecho de la Unión o del estado
          miembro correspondiente.</p>

        <p>El ejercicio de los derechos se podrá efectuar adjuntando copia escaneada del DNI o documento equivalente,
          indicando el derecho que se pretende ejercitar y respecto a qué tratamiento a través de las siguientes
          vías:</p>

        <p>Ante el Delegado de Protección de Datos mediante correo electrónico a dpd@gravidabcn.com.<br/>
          Mediante correo electrónico a gravida@gravidabcn.com<br/>
          Mediante correo postal a: Av. Diagonal, 660, planta 16, 08034 Barcelona.<br/>
          Su solicitud de ejercicio de derecho será atendida por CIRHAB en el plazo máximo de un mes. En aquellos casos
          que se traten de solicitudes especialmente complejas, el plazo de respuesta podrá extenderse hasta dos meses
          más.</p>

        <p>Finalmente, podrá presentar una reclamación ante la Agencia Española de Protección de Datos a través de su
          sede electrónica.</p>

        <h3>Uso del correo electrónico</h3>
        <p>CIRHAB y sus empleados utilizarán el correo electrónico corporativo con la máxima diligencia y
          confidencialidad. En este sentido, si usted tuviera la necesidad de proporcionarnos alguna documentación, le
          recomendamos que lo haga a través del área privada de nuestra web y, de no ser posible, nos lo envíe cifrado
          por correo electrónico, adjuntando la contraseña de manera separada.</p>

        <h3>Política de Cookies</h3>
        <p>Una cookie es un archivo o dispositivo que se descarga en el terminal de un usuario con la finalidad de
          almacenar datos que podrían ser actualizados y recuperados por la entidad responsable de su instalación.</p>

        <p>Puede consultar en el siguiente enlace nuestra política de Cookies, así como configurar el consentimiento del
          uso de estas.</p>

        <p>CIRHAB se reserva el derecho a modificar sin previo aviso su Política de Privacidad, de acuerdo con su propio
          criterio, motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección
          de Datos. De acuerdo con lo anterior, le sugerimos que revise nuestra Política de Privacidad
          periódicamente.</p>

        <p>Marzo 2022</p>
      </div>
    </Fragment>
  )
}
