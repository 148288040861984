import PrediagnosisWorkflow from "../Workflows/PrediagnosisWorkflow";
import storeAnswer from "../Store/storeAnswer";

export function isPrediagnosisWorkflow(workflow) {
  return workflow.name === 'prediagnosis' && workflow.nextStep > 0
}
export function runPrediagnosisWorkflowHandler(workflow, dispatcher, messageFormContext) {
  if(workflow.nextStep === 15) {
    dispatcher({'type': 'setWorkflowParameters', 'payload': {name: messageFormContext.appState.message}})
  }
  if(workflow.nextStep === 16) {
    dispatcher({'type': 'setWorkflowParameters', 'payload': {email: messageFormContext.appState.message}})
  }
  if(workflow.nextStep === 17) {
    dispatcher({'type': 'setWorkflowParameters', 'payload': {phone: messageFormContext.appState.message}})
  }

  const answer = PrediagnosisWorkflow(dispatcher, workflow)
  storeAnswer(dispatcher, answer)
}
