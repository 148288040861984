const initialState = {
  disclaimer: false,
}
const disclaimerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'showDisclaimer':
      return {
        ...state,
        disclaimer: true,
      }
    case 'hideDisclaimer':
      return {
        ...state,
        disclaimer: false,
      }
    default:
      return state
  }
}

export default disclaimerReducer
