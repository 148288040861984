import storeAnswer from "../Store/storeAnswer";
import ReservationWorkflow from "../Workflows/ReservationWorkflow";

export function isReservationWorkflow(workflow) {
  return workflow.name === 'reservation'
}

export function runReservationWorkflowHandler(workflow, dispatcher, messageFormContext) {
  const answer = ReservationWorkflow(dispatcher, workflow, messageFormContext)
  storeAnswer(dispatcher, answer)
}
