import processReservation from "../Processes/ProcessReservation";

export default function ReservationWorkflow(dispatcher, workflow, messageFormContext) {
  const setNextStep = (step) => {
    dispatcher({'type': 'setWorkflowNextStep', 'payload': step})
  }

  switch (workflow.nextStep) {
    case 1: {
      setNextStep(2)
      document.getElementById("messageInput").disabled = false;
      return '¿Qué día y hora quieres reservar (indícalo en formato dd/mm/aaaa hh:mm)?'
    }

    case 2: {
      dispatcher({'type': 'setWorkflowParameters', 'payload': {datetime: messageFormContext.appState.message}})
      setNextStep(3)
      return '¿Cuál es tu nombre?'
    }

    case 3: {
      dispatcher({'type': 'setWorkflowParameters', 'payload': {name: messageFormContext.appState.message}})
      setNextStep(4)
      return '¿Cuáles son tus apellidos?'
    }

    case 4: {
      dispatcher({'type': 'setWorkflowParameters', 'payload': {surname: messageFormContext.appState.message}})
      setNextStep(5)
      return '¿Cuál es tu teléfono?'
    }

    case 5: {
      dispatcher({'type': 'setWorkflowParameters', 'payload': {phone: messageFormContext.appState.message}})
      setNextStep(0)
      return '¿Cuál es tu email?'
    }

    case 0: {
      dispatcher({'type': 'setWorkflowParameters', 'payload': {email: messageFormContext.appState.message}})
      processReservation(
        workflow.parameters.datetime,
        workflow.parameters.name,
        workflow.parameters.surname,
        workflow.parameters.phone,
        workflow.parameters.email
      )
      dispatcher({'type': 'workflowReset'})
      return '¡Gracias! Tu reserva ha sido realizada con éxito.'
    }

    default: {
      return 'Step not found in prediagnosis workflow'
    }
  }
}
