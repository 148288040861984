import storeAnswer from "../Store/storeAnswer";
import createAIAnswer from "../AIQueries/CreateAIAnswer";
import processAnswer from "../Processes/ProcessAnswer";

export function isNoWorkflow(workflow) {
  return workflow.name === ''
}
export function runNoWorkflowHandler(dispatcher, messageFormContext, messages) {
  dispatcher({'type': 'workflowReset'})
  createAIAnswer(messages, messageFormContext.appState.message).then((answer) => {
    processAnswer(dispatcher, answer, messageFormContext)
  }).catch((e) => {
    storeAnswer(dispatcher, 'Lo siento, se ha producido un error. Comprueba tu conexión a internet y vuelve a intentarlo. (' + e.toString() + ')');
  })
}
