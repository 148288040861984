import storeQuestion from "../functions/Store/storeQuestion";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import handlePrediagnosisWorkflow from "../functions/workflowHandlers/handlePrediagnosisWorkflow";
import handleDefaultWorkflow from "../functions/workflowHandlers/handleDefaultWorkflow";

function ButtonOption({button, isButtonPressed, onClick}) {
    return (
        <button onClick={() => onClick(button)} className="buttonOption" disabled={isButtonPressed}>
            {button}
        </button>
    );
}

export default function ButtonsMessage({message}) {
    const dispatcher = useDispatch()
    const messages = useSelector(state => state.messages.messages)
    const workflow = useSelector(state => state.workflow.workflow)
    const json = JSON.parse(message.message)
    const [isButtonPressed, setIsButtonPressed] = useState(false);

    const handleClick = (button) => {
        setIsButtonPressed(true);
        storeQuestion(dispatcher, button);

        handlePrediagnosisWorkflow(workflow.name, workflow.nextStep, button, dispatcher, workflow)
        handleDefaultWorkflow(workflow.name, messages, button, dispatcher)
    }

    return (
        <div key={message.key} className={message.type}>
            <p>{json.response}</p>
            <p>{json.buttons.map((button, index) => <ButtonOption key={index} button={button} isButtonPressed={isButtonPressed}
                                                                  onClick={handleClick}/>)}</p>
        </div>
    )
}