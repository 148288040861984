const initialState = {
  workflow: {
    name: '',
    nextStep: 1,
    parameters: {},
  },
}
const WorkflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setWorkflow':
      return {
        ...state,
        workflow: {
          name: action.payload,
          nextStep: state.workflow.nextStep,
          parameters: state.workflow.parameters,
        }
      }
    case 'setWorkflowNextStep':
      return {
        ...state,
        workflow: {
          name: state.workflow.name,
          nextStep: action.payload,
          parameters: state.workflow.parameters,
        }
      }
    case 'setWorkflowParameters':
      return {
        ...state,
        workflow: {
          name: state.workflow.name,
          nextStep: state.workflow.nextStep,
          parameters: {
            ...state.workflow.parameters,
            ...action.payload
          },
        }
      }
    case 'workflowReset':
      return {
        ...state,
        workflow: {
          name: '',
          nextStep: 1,
          parameters: {},
        },
      }
    default:
      return state
  }
}

export default WorkflowReducer
