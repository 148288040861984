import {Fragment} from "react";
import storeQuestion from "../functions/Store/storeQuestion";
import storeAnswer from "../functions/Store/storeAnswer";
import processLead from "../functions/Processes/ProcessLead";
import {useDispatch, useSelector} from "react-redux";
import {useStateContext} from "../contexts/StateContext";
import createPrediagnosisAIAnswer from "../functions/AIQueries/CreatePrediagnosisAIAnswer";

export default function MessageCheckbox(props) {
  const dispatcher = useDispatch()
  const messageFormContext = useStateContext()
  const messages = useSelector((state) => state.messages.messages)

  const handleCheckboxClick = (name, phone, email) => {

    storeQuestion(dispatcher, 'Acepto la política de privacidad')
    messageFormContext.setAppState({...messageFormContext.appState, messageInput: ''})

    const answer = "Gracias por aceptar la política de privacidad. En breves momentos también recibirás tu pre-diagnóstico a tu correo electrónico."
    storeAnswer(dispatcher, answer)

    const questionToAI = "Quiero información sobre los dos tratamientos de fertilidad que se ajustan mejor a mi caso y que me expliques cómo funcionan. No devuelvas json en la respuesta. Haz un resumen sencillo.";

    createPrediagnosisAIAnswer(messages, questionToAI).then(aiAnswer => {
      const prediagnosticAnswer = "A continuación te ofrecemos tu prediagnóstico: " + aiAnswer;
      storeAnswer(dispatcher, prediagnosticAnswer)
      processLead(name, phone, email, messages, prediagnosticAnswer)
      document.getElementById("MessageCheckbox").disabled = true
      document.getElementById("messageInput").focus()
      dispatcher({'type': 'workflowReset'})
    })

    document.getElementById("messageInput").disabled = false;
    document.getElementById("messageInput").value = '';
  }

  return (
    <Fragment>
      {props.response}<br/>
      <input type="checkbox" id="MessageCheckbox" onClick={() => {
        handleCheckboxClick(props.name, props.phone, props.email)
      }}/> He leído y acepto la <button onClick={(event) => {
      event.preventDefault()
      dispatcher({'type': 'showDisclaimer'})
    }
    }>Política de privacidad</button>
    </Fragment>
  )
}
