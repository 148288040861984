export default function getHistory(messages) {
  let messagesString = "";
  messages.forEach((message) => {
    if (message.type === "question") {
      messagesString = messagesString + "Prompt: " + message.message + "\n"
    } else {
      messagesString = messagesString + "Completion: " + message.message + "\n"
    }
  })
  return messagesString
}
