export default function PrediagnosisWorkflow(dispatcher, workflow) {
  const setNextStep = (step) => {
    dispatcher({'type': 'setWorkflowNextStep', 'payload': step})
  }

  const makeButton = (response, buttons) => {
    return JSON.stringify({"type": "buttons", "response": response, "buttons": buttons})
  }

  switch (workflow.nextStep) {
    case 1: {
      setNextStep(2)
      document.getElementById("messageInput").disabled = true;
      return makeButton("Selecciona tu caso:", ["Mujer sin pareja", "Mujer con pareja masculina", "Mujer con pareja femenina"])
    }
    case 2: {
      setNextStep(3)
      document.getElementById("messageInput").disabled = false;
      return makeButton("Selecciona tu franja de edad:", ["Menor de 35 años", "Entre 35 y 37 años", "Entre 38 y 40 años", "Entre 41 y 42 años", "Mayor de 42 años"])
    }
    case 3: {
      setNextStep(4)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Cómo es tu reserva ovárica?", ["Buena", "Baja", "No lo sé"])
    }
    case 4: {
      setNextStep(6)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Tienes alguna de las trompas obstruidas?", ["Sí", "No", "No lo sé"])
    }
    case 6: {
      switch (workflow.parameters.prediagnosisType) {
        case "Mujer sin pareja": {
          setNextStep(14)
          break
        }
        case "Mujer con pareja masculina": {
          setNextStep(7)
          break
        }
        case "Mujer con pareja femenina": {
          setNextStep(10)
          break
        }
        default: {
          setNextStep(1)
        }
      }
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Tienes enfermedades genéticas hereditarias?", ["Sí", "No", "No lo sé"])
    }
    case 7: {
      setNextStep(8)
      document.getElementById("messageInput").disabled = true;
      return makeButton("Ahora vamos a realizarte unas preguntas sobre tu pareja. ¿Cómo es el seminograma de tu pareja?", ["Seminograma normal", "Seminograma alterado leve", "Seminograma alterado moderado", "Seminograma alterado severo", "Azoospermia", "No lo sé"])
    }
    case 8: {
      setNextStep(9)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Se ha realizado tu pareja una vasectomía?", ["Sí", "No"])
    }
    case 9: {
      setNextStep(14)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Tu pareja tiene enfermedades genéticas hereditarias?", ["Sí", "No", "No lo sé"])
    }
    case 10: {
      setNextStep(11)
      document.getElementById("messageInput").disabled = false;
      return "Ahora vamos a realizarte unas preguntas sobre tu pareja. ¿Cuál es su edad?"
    }
    case 11: {
      setNextStep(12)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Tiene ella las trompas obstruidas?", ["Sí", "No", "No lo sé"])
    }
    case 12: {
      setNextStep(13)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Cómo es la reserva ovárica de tu pareja?", ["Buena", "Baja", "No lo sé"])
    }
    case 13: {
      setNextStep(14)
      document.getElementById("messageInput").disabled = true;
      return makeButton("¿Tiene tu pareja enfermedades genéticas hereditarias?", ["Sí", "No", "No lo sé"])
    }
    case 14: {
      setNextStep(15)
      document.getElementById("messageInput").disabled = false;
      return ('Para poder enviarte el pre-diagnóstico necesitamos los siguientes datos de contacto:\n ¿Cuál es tu nombre y apellido/s?')
    }
    case 15: {
      setNextStep(16)
      document.getElementById("messageInput").disabled = false;
      return ('¿Cuál es tu correo electrónico?')
    }
    case 16: {
      setNextStep(17)
      document.getElementById("messageInput").disabled = false;
      return ('¿Cuál es tu teléfono?')
    }
    case 17: {
      setNextStep(0)
      document.getElementById("messageInput").disabled = true;
      const privacyPolicyResponse = {
        type: 'privacyPolicy',
        response: 'Para finalizar el pre-diagnóstico, por favor, confirma que has leído y aceptas la política de privacidad de Gravida.',
        name: workflow.parameters.name,
        email: workflow.parameters.email,
        phone: workflow.parameters.phone
      }
      return (JSON.stringify(privacyPolicyResponse))
    }

    default: {
      document.getElementById("messageInput").disabled = false;
      return 'Step not found in prediagnosis workflow'
    }
  }
}
