import {OpenAI} from "openai";
import {API_TOKEN} from "../../config/config";
import getHistory from "./getHistory";
import getPrediagnosisTraining from "../AITraining/getCustomTraining";

export default async function createPrediagnosisAIAnswer(messages, question) {
  const openai = new OpenAI({apiKey: API_TOKEN, dangerouslyAllowBrowser: true});

  const training = getPrediagnosisTraining()

  const response = await openai.completions.create({
    model: "gpt-3.5-turbo-instruct",
    prompt: training + getHistory(messages) + "Prompt: " + question + "\nCompletion: ",
    temperature: 0.4,
    max_tokens: 500,
    top_p: 1,
    n: 1,
    frequency_penalty: 0.0,
    presence_penalty: 0.6,
    stop: [" Prompt:", " Completion:"],
  });

  let answer = response.choices[0].text;
  if (answer.includes("Prompt")) {
    answer = answer.split("Prompt:")[0];
  }

  return answer;
}
