import axios from "axios";

export default function processLead(name, phone, email, messages, prediagnosticAnswer) {
  const allMessagesString = messages.map((message) => {
    return message.type + ': ' + message.message + '<br/>'
  })

  axios.post('https://api.gravidachat.com/index.php', 'email=danielraya@seo.barcelona&subject=Lead recibido desde Gravi&html_body=<p>Hola!</p><p>Se ha recibido un lead con los siguientes datos:</p><p>Nombre: ' + name + '<br />Teléfono: ' + phone + '<br />Email: ' + email + '<br />Historial prediagnóstico:<br/>' + allMessagesString + '<br/>Prediagnostic Answer: ' + prediagnosticAnswer + '</p>')

  axios.post('https://api.gravidachat.com/index.php', 'email='+email+'&subject=Aquí tienes tu pre-diagnóstico&html_body=<p>Hola!</p><p>A continuación puedes ver tu prediagnóstico:<br/><br/>' + prediagnosticAnswer + '</p>')
}
