import PrediagnosisWorkflow from "../Workflows/PrediagnosisWorkflow";
import storeAnswer from "../Store/storeAnswer";

export default function handlePrediagnosisWorkflow(name, nextStep, prediagnosisType, dispatcher, workflow) {
    if (name === "prediagnosis") {
        if (nextStep === 2) {
            dispatcher({type: 'setWorkflowParameters', payload: {prediagnosisType}});
        }

        if (nextStep > 0) {
            const answer = PrediagnosisWorkflow(dispatcher, workflow);
            storeAnswer(dispatcher, answer);
        }
    }
}